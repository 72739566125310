import React from "react";
import {
  Grid,
  GridList,
  GridListTile,
  ListSubheader,
  Button,
  GridListTileBar,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { mergeClasses } from "@material-ui/styles";
import { Link } from "gatsby";
import { gallery as photos } from "../../config/gallery.yml";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 500,

    margin: 0,
    zIndex: 4,
    width: "100%",
  },
  title: {
    "& *": {
      color: "black",
    },
    marginBottom: 16,
  },
  img: {
    // height: 400,
  },
  imgCard: {
    height: 350,
    "& img": {
      height: "100%",
      width: "auto !important",
      borderRadius: 8,
    },
    "@media (max-width: 850px)": {
      // maxWidth: 300,
      height: 200,
    },
  },
  carousel: {
    maxWidth: "94vw",
    backgroundColor: "rgba(140,0,140,0.1)",
    borderRadius: 8,
    "& ul": {
      padding: 0,
    },
  },
}));
const Gallery = () => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={classes.root}>
      {/* <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={classes.title}
      >
        <Typography>Fotos del establecimiento ubicado en Santa Cruz</Typography>
        <a href="https://www.google.com/maps/place/Prever+-+Sal%C3%B3n+Velatorio/@-17.7941441,-63.16884,16z/data=!4m5!3m4!1s0x93f1e85d62ea77cb:0xfd9e12d454893aca!8m2!3d-17.7943484!4d-63.1657501">
          <Button variant="contained" color="primary">
            Ver en mapa
          </Button>
        </a>
      </Box> */}
      <Carousel className={classes.carousel} emulateTouch infiniteLoop>
        {photos.map((photo, i) => {
          return (
            <div key={i} className={classes.imgCard}>
              <img
                className={classes.img}
                src={photo.src}
                alt={photo.title}
              ></img>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Gallery;
